<template>
  <div
    class="page beijingCityRepresentativeAncientTree-mobile-page"
    :style="{ backgroundImage: `url(${pageBgImg})` }"
  >
    <NavigatorBar />
    <div class="container">
      <div class="detailContainer">
        <van-swipe
          @change="onChange"
          class="my-swipe"
          :autoplay="3000"
          :show-indicators="false"
          ref="myVantSwipe"
        >
          <van-swipe-item v-for="tree in hasDetailTrees" :key="tree.title">
            <div class="scrollContainer"><DetailPure :detail="tree" /></div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div class="indicators flex flex-ct">
      <div
        v-for="(tree, index) in hasDetailTrees"
        :key="tree.title"
        :class="{
          item: true,
          actived: current === index
        }"
      ></div>
    </div>
    <div class="flex bottom-btns">
      <div
        class="btn flex-1 flex flex-ct p-v-12 m-r-10"
        @click="gotoPage('/beijing-ancient-tree-resources')"
      >
        北京市古树资源情况
      </div>
      <div
        class="btn flex-1 flex flex-ct p-v-12 m-l-10"
        @click="gotoPage('/beijing-ancient-tree-map')"
      >
        北京市古树观赏地图
      </div>
    </div>
    <div class="flex flex-ct footer p-b-20">
      ·奥迪中国与北京绿化基金会联合呈现·
    </div>
  </div>
</template>

<script>
import myMixinDetail from "./detail.js";
import DetailPure from "./detailPure_m.vue";

export default {
  components: {
    DetailPure
  },
  mixins: [myMixinDetail],

  data() {
    return {
      current: 0,
      pageBgImg: require("@/images/bg-2.jpg")
    };
  },
  computed: {
    index() {
      const index = this.trees.findIndex(
        (tree) => tree.title === this.$route.query.id
      );
      return index;
    },
    hasDetailTrees() {
      return this.trees.filter((item) => item.detail);
    }
  },
  mounted() {
    if (this.$refs.myVantSwipe) {
      this.$refs.myVantSwipe.swipeTo(this.index);
    }
  },
  activated: function () {
    this.$refs.myVantSwipe && this.$refs.myVantSwipe.swipeTo(this.index);
  },
  methods: {
    onChange(index) {
      this.current = index;
      const title = this.$route.query.id;
      if (title !== this.hasDetailTrees[index].title) {
        this.$router.replace({
          path: "/beijing-city-representative-ancient-tree-detail",
          query: {
            id: this.hasDetailTrees[index].title
          }
        });
      }
    },
    gotoPage(routePath) {
      if (routePath) {
        this.$router.push(routePath);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.page {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #fff;
}
.beijingCityRepresentativeAncientTree-mobile-page {
  .container {
    margin: 126px 32px 0;
    border-radius: 12px;
    background: rgba($color: #000000, $alpha: 0.4);
    border: 1px solid rgba($color: #ffffff, $alpha: 0.4);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .detailContainer {
      flex-grow: 0;
      padding-bottom: 10px;
      background: #fff;
      border-radius: 12px;
      height: 100%;

      .my-swipe {
        height: 100%;
        overflow: visible;
        .van-swipe-item {
          border-radius: 11px;
          // overflow: hidden;
          overflow-y: auto;
          max-height: 100%;
          .scrollContainer {
            max-height: 100%;
            overflow-y: auto;
          }
        }
      }
    }
  }
  .indicators {
    margin-top: 10px;
    .item {
      $size: 8px;
      width: $size;
      height: $size;
      border-radius: 50%;
      background: #ebedf0;
      opacity: 0.8;
      overflow: hidden;
      margin-right: calc($size);
      &:last-child {
        margin-right: 0;
      }
      &.actived {
        background: #40bf65;
        opacity: 1;
      }
    }
  }

  .bottom-btns {
    font-size: 10px;
    margin: 10px 32px 20px;
    .btn {
      background: #0000007a;
      border-radius: 20px;
      border: 1px solid #ffffff61;
    }
  }
  .footer {
    font-size: 10px;
  }
}
</style>
