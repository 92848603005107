<template>
  <div class="page">
    <div class="flex-1 mainArea" style="flex-grow: 10">
      <div class="container flex-1">
        <div class="top">
          <div :class="[' flex flex-ct m-t-15']" v-if="title">
            <div class="title p-h-8">{{ title }}</div>
          </div>
          <div :class="['homePagesubtitle m-t-8']" v-if="subtitle">
            {{ subtitle }}
          </div>
        </div>
        <div :class="['imgArea m-t-8']">
          <router-link :to="routePath" v-if="routePath">
            <img
              class="img_w_100 img_h_100 img_cover mainImg"
              :src="mainImg"
              alt=""
            />
          </router-link>
          <img
            v-else
            v-download="{
              url: downloadFile.path,
              fileName: downloadFile.fileName
            }"
            class="img_w_100 img_h_100 img_cover mainImg"
            :src="mainImg"
            alt=""
          />
        </div>
        <router-link :to="routePath" v-if="routePath">
          <div class="more flex flex-ct">
            <img class="img" src="@/images/more_arrow_right.png" alt="" />
            <div class="p-h-15">点击探索更多</div>

            <img class="img" src="@/images/more_arrow_left.png" alt="" />
          </div>
        </router-link>
        <div
          class="more flex flex-ct"
          v-else
          v-download="{
            url: downloadFile.path,
            fileName: downloadFile.fileName
          }"
        >
          <img class="img" src="@/images/more_arrow_right.png" alt="" />
          <div class="p-h-15">点击探索更多</div>

          <img class="img" src="@/images/more_arrow_left.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "./index.js";
export default {
  mixins: [mixin],
  props: {
    dotActiveIndex: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: ""
    },
    subtitle: {
      type: String,
      default: ""
    },
    routePath: {
      type: String,
      default: ""
    },
    pageBgImg: {
      type: String,
      default: ""
    },
    mainImg: {
      type: String,
      default: ""
    },
    clickNextPage: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    isLastPage() {
      return !!this.$slots.default;
    },
    showProducer() {
      return this.dotActiveIndex === 3;
    },
    partImg() {
      switch (this.dotActiveIndex) {
        case 0:
          return this.part1;
        case 1:
          return this.part1;
        case 2:
          return this.part2;
        case 3:
          return this.part3;

        default:
          return this.part1;
      }
    }
  },
  data() {
    return {
      part1: require("@/images/part1.png"),
      part2: require("@/images/part2.png"),
      part3: require("@/images/part3.png")
    };
  }
};
</script>

<style lang="scss" scoped>
.page {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.mainArea {
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  .container {
    overflow: hidden;
    border-radius: 12px;
    background: rgba($color: #000000, $alpha: 0.4);
    border: 1px solid rgba($color: #ffffff, $alpha: 0.4);
    display: flex;
    flex-direction: column;
  }
  .top {
    .part {
      height: 10px;
    }
    .dot {
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background-color: #ffffff;
      display: block;
      margin-left: 4px;
      &.active {
        background-color: #40c066;
      }
    }
  }
  .title {
    font-size: 26px;
    color: #ffffff;
    background-image: url("~@/images/page-top-yinhao1.png"),
      url("~@/images/page-top-yinhao2.png");
    background-repeat: no-repeat;
    background-position: top left, bottom right;
    background-size: 7px auto, 7px auto;
  }
  .homePagesubtitle {
    font-size: 13px;
    color: #ffffff;
    text-align: center;
    line-height: 1em;
  }
  .line {
    width: 93px;
    height: 2px;
    background: #ffffff7e;
    .linedot {
      background: #ffffff;
      height: 2px;
      width: 32px;
    }
  }
  .imgArea {
    cursor: pointer;
    overflow: hidden;
    border-radius: 10px 10px 0 0;
    flex-shrink: 1;
    flex-grow: 1;
    position: relative;
    .mainImg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  .more {
    height: 53px;
    font-size: 13px;
    color: #a3a3a3;
    background: #fff;
    cursor: pointer;
    .img {
      width: 35px;
    }
  }
}

.producer {
  text-align: center;
  font-size: 10px;
  padding-bottom: 22px;
}
.arrowDown {
  padding-bottom: 22px;
  opacity: 0;
  .img {
    width: 28px;
  }
}
.footer {
  flex-shrink: 0;
  // height: 150px;
}
// .arrowDown,
// .producer {
//   position: absolute;
//   left: 50%;
//   transform: translateX(-50%);
//   bottom: 22px;
//   z-index: 10;
// }
</style>
